import { BASE_URL } from "../confiig"
let token = null
// const token = sessionStorage.getItem('token');

const updateToken = () => {
  token = sessionStorage.getItem('token');
};

updateToken(); // Set the initial value of the token

export const getAllUsers = async () => {
  updateToken()
  if (token !== null) {
    return fetch(`${BASE_URL}/users`, {
      method: "GET",
      headers: {
        "token": `${token}`,
        "Content-Type": "application/json",
      },
    })
  } else {
    // Handle the case when the token is null
    return Promise.reject(new Error("Token is null"));
  }
}

export const deleteUser = async (usersId) => {
  updateToken()
  if (token !== null) {
    return fetch(`${BASE_URL}/users/${usersId}`, {
      method: "DELETE",
      headers: {
        "token": `${token}`,
        "Content-Type": "application/json",
      },
    })
  } else {
    // Handle the case when the token is null
    return Promise.reject(new Error("Token is null"));
  }
}

export const addProduct = async (addedUser) => {
  updateToken()
  if (token !== null) {
    return fetch(`${BASE_URL}/products`,
      {
        method: "POST",
        headers: {
          "token": `${token}`,
        },
        body: addedUser,
      }
    );
  } else {
    // Handle the case when the token is null
    return Promise.reject(new Error("Token is null"));
  }
}

export const updateUser = async (usersData) => {
  updateToken()
  if (token !== null) {
    return fetch(
      `${BASE_URL}/users/${usersData.id}`,
      {
        method: "PUT",
        headers: {
          "token": `${token}`,

          "Content-Type": "application/json"
        },
        body: JSON.stringify(usersData.editedUser)
      }
    );
  } else {
    // Handle the case when the token is null
    return Promise.reject(new Error("Token is null"));
  }
}