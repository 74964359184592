import React, { useState, useEffect, useContext } from "react";

import Modal from "react-modal";
import "./Product.scss";
import Pagination from "../../components/Pagination/Pagination";
// import Sidebar from "../../components/Sidebar/SideBar";

import AddProduct from "./AddProduct";
import EditProduct from "./EditProduct";
import { BASE_URL, IMG_URL } from "../../confiig";
import { addProduct, deleteProduct, getProducts } from "../../api/product";
import { EcomContext, ProductContext } from "../../context/Context";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Puff } from "react-loader-spinner";
import Loader from '../Loader/Loader'
const token = sessionStorage.getItem('token');
function Product(data) {

  
  const { myCategory } = useContext(EcomContext);
  // console.log("data", prod);
  let token = sessionStorage.getItem("token");
  const [addModalIsOpen, setAddModalIsOpen] = useState(false);
  // Add these state variables at the beginning of the component
const [showModal, setShowModal] = useState(false);
const [selectedItemId, setSelectedItemId] = useState(null); // To store the selected item ID for deletion
const openModal = (id) => {
  setSelectedItemId(id);
  setShowModal(true);
};
const closeModal = () => {
  setShowModal(false);
};
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width:"100%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
  const [ediModalIsOpen, setEditModalIsOpen] = useState(false);
  // const [count, setCount] = useState(0);
  const [editedProductId, setEditedProductId] = useState("");
  const [item, setItem] = useState([]);
  const [isChanged, setIsChanged] = useState(false);
  const [addedProduct, setAddedProduct] = useState({});
  const [editedProductDetails, setEditedProductDetails] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  // const [Products, setProducts] = useState([]);

  // useEffect(() => {
  //   setIsLoading(true)
  //   setTimeout(() => {
  //     setIsLoading(false)
  //   }, 400)
  // }, [])
  useEffect(() => {

    const fetchProducts = async () => {
      try {
        setIsLoading(true)
        const repsonseProducts = await getProducts();
        const productsData = await repsonseProducts.json();

        if (productsData.type === "success") {
          setItem([...productsData.products].reverse());
          // setIsChanged(!isChanged)
          // setCount(count => count + 1);
          setIsLoading(false) 
        }
      } catch (error) {
        console.log(error)
        setIsLoading(false)

      } finally {
        setIsLoading(false)
      }
    }
    fetchProducts()
    // .catch((error) => console.log(error));
  }, [isChanged]);

  useEffect(() => {
  console.log("productItem",item)
},[])


  // const history = useHistory();
  // const navigate = useNavigate()
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;
  // const [itemCount, setItemCount] = useState(0);

  // const [displayedItems, setDisplayedItems] = useState(items);
  const totalPages = Math.ceil(item.length / itemsPerPage);// for example purposes only
  // const totalPages = Math.ceil(categories.length / itemsPerPage); // for example purposes only 

 
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };


 const getCurrentPageData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return item.slice(startIndex, endIndex);
  };


  
  useEffect(() => {
    getCurrentPageData();
  }, [item]);
  
  // const customStyles = {
  //   content: {
  //     padding: 0,
  //   },
  // };

  // const getCurrentPageData = () => {
  //   const startIndex = (currentPage - 1) * itemsPerPage;
  //   const endIndex = startIndex + itemsPerPage;
  //   return categories.slice(startIndex, endIndex);
  // };
  
  const handleAddProduct = (isChanged) => {
    setAddModalIsOpen(true);
    // history.push('/AddProduct')
  };
  // const onAddProductSubmit = (formData) => {

  // };

  const editClose = (data) => {
    setEditModalIsOpen(data);
  };

  const addClose = (data) => {
    setAddModalIsOpen(data);
  };

  const handleEditProduct = (productId) => {
    // Find the product object in the categories list
    const productToEdit = item.find((product) => product._id === productId);

    // Set the edited category name and ID

    setEditedProductDetails(productToEdit);
    setEditedProductId(productId);

    // Open the edit popup
    setEditModalIsOpen(true);
    // setEditModalIsOpen(true)
  };


  async function handleDeleteProduct(productId) {
  

    try {
      const resDelProd = await deleteProduct(productId)

      const delProdData = await resDelProd.json()

      if (delProdData.type === "success") {
        setIsChanged(!isChanged);
        toast.success(delProdData.message);
        closeModal(); 

      }
    } catch (error) {
      toast.error(error)
    }

  }

 
  const handleAddSubmit = async (product) => {
    setAddedProduct(product);

    try {
      const response = await fetch("https://api.pragatimitra.in/api/v1/products", {
        method: "POST",
        body: addedProduct,
        headers: {
          'token': `${token}`,
        }
      });

      const data = await response.json();

      if (data.type === "success") {
        addClose(false);
        toast.success(data.message);
        setIsChanged(!isChanged);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  const filteredProducts = getCurrentPageData().filter(item => {
    return item.title && item.title.toLowerCase().includes(searchTerm.toLowerCase());
  });
  // console.log("filteredProducts",filteredProducts)

  return (
    <div className="product_card">
      <div className="product_header">
        <h3>Service List</h3>
      </div>
      <div className="d-flex justify-content-between align-item-center category-header">
        <div className="order-search">
          <input
            type="text"
            placeholder="Search orders"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button>Search</button>

        </div>
        <div className="addItembtn">
          <button
            type="button"
            className="btn btn-success"
            onClick={handleAddProduct}
          >
            Add Products
          </button>
        </div>
      </div>

      <div>
        <table>
          <thead>
            <tr>
              <th>Product Image</th>
              <th>Product Title</th>
              <th>Description</th>
             
              <th>Price</th>
              <th>Categories</th>
              <th>Edit</th>
              <th>Remove</th>
            </tr>

          </thead>
          <tbody>
            {isLoading ? <div>
              <Loader />
            </div> : filteredProducts.map((item) => (
              <tr key={item._id}>
                <td>
                  {/* {console.log(item)} */}
                  <img src={`${IMG_URL}/${item.image}`} alt={item.image} />
                </td>
                <td>{item.title}</td>
                <td className="details">{item.description}</td>
               
                <td>₹ {item.price}</td>
                <td>{item.categories}</td>
                <td>
                  <button
                    className="editIcon"
                    onClick={() => handleEditProduct(item._id)}
                  >
                    <FontAwesomeIcon icon={faEdit} className='editIcon' />
                  </button>
                </td>
                <td>
  <button
    className="removeIcon"
    onClick={() => openModal(item._id)}
  >
    <FontAwesomeIcon icon={faTrash} className="removeIcon " />
  </button>
</td>
{/* Confirmation Modal */}
<Modal
  isOpen={showModal}
  onRequestClose={closeModal}
  style={customStyles}
>
  <div className="modal-header">
    <h2>Confirm Delete</h2>
  </div>
  <div className="modal-body">
    <p>Are you sure you want to delete this item?</p>
  </div>
  <div className="modal-footer">
    <button className="yesButton" onClick={() => handleDeleteProduct(selectedItemId)}>Yes</button>
    <button className="noButton" onClick={closeModal}>No</button>
  </div>
</Modal>

              </tr>
            ))}
          </tbody>
        </table>
        <div>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </div>
      </div>

      <Modal
        className="Modal"
        overlayClassName="Overlay"
        style={customStyles}
        isOpen={ediModalIsOpen}
        onRequestClose={() => setEditModalIsOpen(false)}
      >
        <EditProduct
          productDetails={editedProductDetails}
          onEditClose={editClose}
          myCategory={myCategory}
          // onEditSubmit={onEditProductSubmit}
          onUpdateProduct={() => setIsChanged(!isChanged)}
        />
      </Modal>
      <Modal
        className="Modal"
        overlayClassName="Overlay"
        isOpen={addModalIsOpen}
        onRequestClose={() => setAddModalIsOpen(false)}
      >
        <AddProduct onAddClose={addClose} onAddSubmit={handleAddSubmit} />
      </Modal>
    </div>
  );
}

export default Product;