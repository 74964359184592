import { BASE_URL } from "../confiig"
let token = null
let adminId = null
// const token = sessionStorage.getItem('token');

const updateToken = () => {
  token = sessionStorage.getItem('token');
};
const updateAdminId = () => {
  adminId = sessionStorage.getItem('adminId');
};

updateToken(); // Set the initial value of the AdminId


export const getAdminProfile = async () => {
  updateToken()
  updateAdminId()
  if (token !== null && adminId !== null) {
    return fetch(`${BASE_URL}/users/${adminId}`, {
      method: 'GET',
      headers: {
        'token': `${token}`,
        'Content-Type': 'application/json'
      },
    });
  } else {
    // Handle the case when the token is null
    return Promise.reject(new Error("Token is null"));
  }

};

export const OnAdminEditProfile = async (data) => {
  updateToken()
  if (token !== null) {
    return fetch(`${BASE_URL}/users/${adminId}`, {
      method: 'PUT',
      headers: {
        'token': `${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
  }
  else {
    // Handle the case when the token is null
    return Promise.reject(new Error("Token is null"));
  }
}