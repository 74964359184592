import React, { useState, useContext } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IMG_URL } from '../../confiig';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";

import './EditProfile.scss';
import { EcomContext } from '../../context/Context';
import { OnAdminEditProfile } from '../../api/adminProfile';

function EditProfile({
  isFirstName,
  isLastName,
  isPhone,
  isEmail,
  isRole,
  onClose,
  image,
  onUpdateProduct
}) {
  const token = sessionStorage.getItem('token');
  const { adminProfileInfo } = useContext(EcomContext);
  const [firstName, setFirstName] = useState(isFirstName);
  const [lastName, setLastName] = useState(isLastName);
  const [email, setEmail] = useState(isEmail);
  const [mobile, setMobile] = useState(isPhone);
  // const [imageFile, setImageFile] = useState(image);


  const handleFileSelect = (event) => {
    // setImageFile(event.target.files[0]);
    const file = event.target.files[0]
    const data = new FormData();
    data.append('image', file);


    axios
      .post(`https://api.pragatimitra.in/api/v1/users/profile/${adminProfileInfo._id}`, data, {
        headers: {
          'token': `${token}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        // console.log(response,"response")
        toast.success('Profile image updated successfully');
        // onClose();
        onUpdateProduct()
        // window.location.reload();
        // Additional actions if required
      })
      .catch((error) => {
        toast.error('Failed to update profile image');
        // Additional error handling if required
      });
  };

  const onProfileSubmit = async () => {
    const updateAdminData = {
      "email": email,
      "firstName": firstName,
      "lastName": lastName,
      "mobile": mobile,
    }
    const response = await OnAdminEditProfile(updateAdminData)
    const responseData = await response.json()

    if (responseData.type === "success") {
      toast.success(responseData.message)
      onClose()
      onUpdateProduct()
    }
  }

  return (
    <div>
      <div className="card">
        <div className="product_header">
          <h3>Edit Profile</h3>
        </div>
        <div>
          <div className="p-3">
            <div className="profile-container">
              <div className="profile-card">
                <div className="profile-pic" >
                  <img src={`${IMG_URL}/${adminProfileInfo.image}`} alt={adminProfileInfo.image} />
                  <div className="edit-icon">
                    <button className="edit-icon" onClick={() => document.getElementById("upload-input").click()}>
                      <FontAwesomeIcon icon={faCamera} />
                    </button>
                    <input
                      id="upload-input"
                      type="file"
                      onChange={handleFileSelect}
                      style={{ display: "none" }}
                    />
                  </div>
                </div>
                <div className="edit">
                  <button>
                    <img src={`${IMG_URL}/${adminProfileInfo.image}`} alt={adminProfileInfo.image} />
                  </button>
                </div>
              </div>
              <h4 className="text-center pb-3">Profile</h4>
            </div>
            <div className="form-container">
              <div className="form-group">
                <label htmlFor="firstName" className="form-label text-black">
                  First Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="firstName"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  placeholder="Enter First Name"
                />
              </div>
              <div className="form-group">
                <label htmlFor="lastName" className="form-label text-black">
                  Last Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="lastName"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  placeholder="Enter Last Name"
                />
              </div>
              <div className="form-group">
                <label htmlFor="email" className="form-label text-black">
                  Email
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter Email"
                />
              </div>
              <div className="form-group">
                <label htmlFor="mobile" className="form-label text-black">
                  Phone
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="mobile"
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                  placeholder="Enter Contact Number"
                />
              </div>
              <div className="select-role d-flex justify-content-start align-item-center mb-5">
                <span>Role :</span>
                <span> {isRole}</span>
              </div>
              <div className="d-flex justify-content-start align-item-center">
                {/* <input type="file" onChange={handleFileSelect} /> */}
                {/* <FontAwesomeIcon icon={faEdit} /> */}

                <button
                  type="button"
                  className="btn btn-success text-white me-3"
                  onClick={onProfileSubmit}
                >
                  Submit
                </button>
                <button
                  type="button"
                  className="btn btn-danger text-white"
                  onClick={onClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditProfile;






