import React, { useState } from 'react';


import "./SideBar.scss"

import AddProduct from '../../pages/AddProduct/AddProduct'
import Dashboard from '../../pages/dashboard/dashboard';
import Product from '../../pages/AddProduct/Product';
import Order from '../../pages/Order/order';
import CategoryPage from '../../pages/Category/CategoryPage';
import Users from '../../pages/Users/Users';
import EditProfile from '../../pages/EditProfile/EditProfile';
import AdminProfile from '../../pages/EditProfile/AdminProfile';
import Carousel from '../../pages/Carousel/Carousel';
import Referrals from "../../pages/Referrals"

const Sidebar = () => {
  const [activeTab, setActiveTab] = useState('1');
 
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    // <div>
    //   <div className="sidebar">
    //     <a href="#home">DashBoard</a>
    //     <a href="#about">Product</a>
    //     <a href="#services">Orders</a>
    //     <a href="#contact">Sellers</a>
    //   </div>
    // </div>
    <div className="container-fluid">
    <div className='sidebar-main'>
       <div className="sidebar">
            <div
              className={`sidebar-tab ${activeTab === '1' ? 'active' : ''}`}
              onClick={() => handleTabClick('1')}
            //   onMouseEnter={() => handleTabClick('1')}
            >DashBoard
            </div>
            {/* <div
              className={`sidebar-tab ${activeTab === '2' ? 'active' : ''}`}
              onClick={() => handleTabClick('2')}
            //   onMouseEnter={() => handleTabClick('2')}
            >
              Carousel
            </div> */}
            <div
              className={`sidebar-tab ${activeTab === '3' ? 'active' : ''}`}
              onClick={() => handleTabClick('3')}
            //   onMouseEnter={() => handleTabClick('3')}
            >Products
              
            </div>
            <div
              className={`sidebar-tab ${activeTab === '4' ? 'active' : ''}`}
              onClick={() => handleTabClick('4')}
            //   onMouseEnter={() => handleTabClick('3')}
            >Orders
              
            </div>

            <div
              className={`sidebar-tab ${activeTab === '5' ? 'active' : ''}`}
              onClick={() => handleTabClick('5')}
            //   onMouseEnter={() => handleTabClick('3')}
            >
             Category
            </div>
            <div
              className={`sidebar-tab ${activeTab === '6' ? 'active' : ''}`}
              onClick={() => handleTabClick('6')}
            //   onMouseEnter={() => handleTabClick('3')}
            >
             Users 
            </div>
            <div
              className={`sidebar-tab ${activeTab === '7' ? 'active' : ''}`}
              onClick={() => handleTabClick('7')}
            //   onMouseEnter={() => handleTabClick('3')}
            >
             Referrals 
            </div>
            <div
              className={`sidebar-tab ${activeTab === '8' ? 'active' : ''}`}
              onClick={() => handleTabClick('8')}
            //   onMouseEnter={() => handleTabClick('3')}
            >
               My Profile
            </div>
          </div>
          <div className="main-content">
            {activeTab === '1' && (
              <div>
              <Dashboard/>
              </div>
            )}
            {activeTab === '3' && (
              <div>
               <Product/>
              </div>
            )}
            {activeTab === '4' && (
              <div>
                {/* <AddProduct/> */}
                {/* <AddProduct/> */}
                <Order/>
              </div>
            )}
            {activeTab === '5' && (
              <div>
                <CategoryPage/>
                {/* <AddProduct/> */}
                {/* <AddProduct/> */}
              </div>
            )}
            {activeTab === '6' && (
              <div>
                <Users/>
               
              </div>
            )}
             {activeTab === '7' && (
              <div>
                <Referrals/>
               
              </div>
            )}
            {activeTab === '8' && (
             <div>
               <AdminProfile/>
              
             </div>
           )}
            {activeTab === '2' && (
              <div>
                <Carousel/>
               
              </div>
            )}
          </div>
    </div>
    </div>
  );
}

export default Sidebar;
