import React, { useState, useEffect, useContext } from 'react';
import Pagination from '../../components/Pagination/Pagination';
import './index.css';
import { getAllReferrals } from '../../api/referrals';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../Loader/Loader';

const Referrals = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [allReferrals, setReferrals] = useState([]);
  const [isChanged, setIsChanged] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageData, setPageData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const itemsPerPage = 7;
  console.log("allReferrals", allReferrals)
  const totalPages = Math.ceil(allReferrals.length / itemsPerPage);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const getCurrentPageData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return allReferrals.slice(startIndex, endIndex);
  };
  useEffect(() => {
    getCurrentPageData();
  }, [isChanged]);

  useEffect(() => {
    const fetchAllReferrals = async () => {
      try {
        setIsLoading(true);
        const repsonseAllReferrals = await getAllReferrals();
        const allReferrals = await repsonseAllReferrals.json();

        if (allReferrals.type === 'success') {
          setReferrals(allReferrals.referral);
          setPageData(allReferrals.referral);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchAllReferrals();
  }, [isChanged]);


  const filteredUser = getCurrentPageData().filter((item) => {
    return (
      item.userInfo.firstName &&
      item.userInfo.firstName.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  return (
    <div className="product_card">
      <div className="product_header">
        <h3>Referral List</h3>
      </div>
      <div className="user_header">
        <div className="user-search user-header">
          <input
            type="text"
            placeholder="Search referrals"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button>Search</button>
        </div>
      </div>
      <div>
        <table>
          <thead>
            <tr>
              <th>Referrer Name</th>
              <th>Referrer Mobile</th>
              <th>Customer Name</th>
              <th>Customer Mobile</th>
              <th>Customer Address</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <Loader />
            ) : (
              filteredUser.map((item) => (
                <tr key={item._id}>
                  {/* {console.log("getCurrentPageData",userDetails)} */}
                  <td>{item.userInfo.firstName + " " + item.userInfo.lastName}</td>
                  <td>{item.userInfo.mobile}</td>
                  <td>{item.rname}</td>
                  <td>{item.rmobile}</td>
                  <td>{item.raddress}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <div>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default Referrals;
