import React, { useState, useEffect, useContext } from 'react';
import edit from '../../assets/edit.svg';
import remove from '../../assets/Remove.svg';
import Modal from 'react-modal';

import Pagination from '../../components/Pagination/Pagination';
// import Sidebar from "../../components/Sidebar/SideBar";

import AddProduct from '../AddProduct/AddProduct';
import EditProduct from '../AddProduct/EditProduct';
import { BASE_URL, IMG_URL } from '../../confiig';
import { addProduct, deleteProduct, getProducts } from '../../api/product';
import { EcomContext, ProductContext } from '../../context/Context';
import './Users.css';
import { deleteUser, getAllUsers } from '../../api/users';
import EditUsers from './EditUsers';
import AddUsers from './addUsers';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../Loader/Loader';

const Users = () => {
  const [isLoading, setIsLoading] = useState(false);
  const prod = useContext(EcomContext);

  let token = sessionStorage.getItem('token');
  const [addModalIsOpen, setAddModalIsOpen] = useState(false);
  const [ediModalIsOpen, setEditModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [userToDeleteId, setUserToDeleteId] = useState('');
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };
  // Function to open the delete confirmation modal
  const handleDeleteModalOpen = (userId) => {
    setUserToDeleteId(userId);
    setDeleteModalIsOpen(true);
  };

  // Function to close the delete confirmation modal
  const handleDeleteModalClose = () => {
    setDeleteModalIsOpen(false);
    setUserToDeleteId('');
  };
  const [editeduserId, setEditeduserId] = useState('');
  const [userDetails, setUserDetails] = useState([]);
  const [item, setItem] = useState([]);
  const [isChanged, setIsChanged] = useState(false);
  const [addedUser, setAddedUser] = useState({});
  const [editedUserDetails, setEditedUserDetails] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageData, setPageData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const itemsPerPage = 7;
  const totalPages = Math.ceil(userDetails.length / itemsPerPage);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const getCurrentPageData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return userDetails.slice(startIndex, endIndex);
  };
  useEffect(() => {
    getCurrentPageData();
  }, [isChanged]);

  useEffect(() => {
    const fetchAllUsers = async () => {
      try {
        setIsLoading(true);
        const repsonseAllUsers = await getAllUsers();
        const allUsers = await repsonseAllUsers.json();

        if (allUsers.type === 'success') {
          // console.log("allUsers details",allUsers)
          setUserDetails(allUsers.users);
          setPageData(allUsers.users);
          setIsLoading(false);
          // setIsChanged(!isChanged)
          // setCount(count => count + 1);
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };
    fetchAllUsers();
    // .catch((error) => console.log(error));
  }, [isChanged]);

  const handleAddProduct = (isChanged) => {
    setAddModalIsOpen(true);
    // history.push('/AddProduct')
  };
  // const onAddProductSubmit = (formData) => {

  // };
  const handleDeleteUser = async () => {
    try {
      setIsLoading(true);
      const response = await deleteUser(userToDeleteId);
      const data = await response.json();

      if (data.type === 'success') {
        setIsChanged(!isChanged);
        toast.success(data.message);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error('Error deleting user');
    } finally {
      setIsLoading(false);
      handleDeleteModalClose();
    }
  };

  const editClose = (data) => {
    setEditModalIsOpen(data);
  };

  const addClose = (data) => {
    setAddModalIsOpen(data);
  };

  const handleEditUsers = (usersId) => {
    // Find the product object in the categories list
    const item = [...userDetails];

    const usersToEdit = item.find((user) => user._id === usersId);

    // Set the edited category name and ID

    setEditedUserDetails(usersToEdit);
    setEditeduserId(usersId);

    // Open the edit popup
    setEditModalIsOpen(true);
    // setEditModalIsOpen(true)
  };

  const filteredUser = getCurrentPageData().filter((item) => {
    return (
      item.firstName &&
      item.firstName.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });
  console.log('filteredProducts', filteredUser);

  return (
    <div className="product_card">
      {/* <div className="d-flex justify-content-between align-item-center category-header"> */}
      <div className="product_header">
        <h3>Users List</h3>
      </div>
      <div className="user_header">
        <div className="user-search user-header">
          <input
            type="text"
            placeholder="Search orders"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button>Search</button>
        </div>
      </div>
      {/* </div> */}
      {/* <div className="addItembtn">
        <button
          type="button"
          className="btn btn-success"
          onClick={handleAddProduct}
        >
          Add Users
        </button>
      </div> */}
      <div>
        <table>
          <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Phone Number</th>
              {/* <th>Category</th> */}
              {/* <th>Categories</th> */}
              <th>Edit</th>
              <th>Remove</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <Loader />
            ) : (
              filteredUser.map((item) => (
                <tr key={item._id}>
                  {/* {console.log("getCurrentPageData",userDetails)} */}
                  <td>{item.firstName}</td>
                  <td>{item.lastName}</td>
                  <td>{item.email}</td>
                  <td>{item.mobile}</td>
                  {/* <td>{item.categories}</td> */}
                  <td>
                    <button
                      className="editContainer"
                      onClick={() => handleEditUsers(item._id)}
                    >
                      <FontAwesomeIcon icon={faEdit} className="editIcon" />
                    </button>
                  </td>
                  <td>
                    <button
                      className="removeContainer"
                      onClick={() => handleDeleteModalOpen(item._id)}
                    >
                      <FontAwesomeIcon icon={faTrash} className="removeIcon" />
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <div>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </div>
      </div>

      <Modal
        className="Modal"
        overlayClassName="Overlay"
        isOpen={ediModalIsOpen}
        onRequestClose={() => setEditModalIsOpen(false)}
      >
        <EditUsers
          userDetails={editedUserDetails}
          onEditClose={editClose}
          // onEditSubmit={onEditProductSubmit}
          onUpdateUser={() => setIsChanged(!isChanged)}
        />
      </Modal>
      {/* <Modal
        className="Modal"
        overlayClassName="Overlay"
        isOpen={addModalIsOpen}
        onRequestClose={() => setAddModalIsOpen(false)}
      >
        <AddUsers onAddClose={addClose} onAddSubmit={handleAddSubmit} />
      </Modal> */}
      {/* Delete Confirmation Modal */}
      <Modal
        className="Modal"
        overlayClassName="Overlay"
        isOpen={deleteModalIsOpen}
        style={customStyles}
        onRequestClose={handleDeleteModalClose}
      >
        <div className="modal-content">
          <h2>Confirm Delete</h2>
          <p>Are you sure you want to delete this user?</p>
          <div className="modal-buttons">
            <button className="btn btn-danger" onClick={handleDeleteUser}>
              Delete
            </button>
            <button
              className="btn btn-secondary"
              onClick={handleDeleteModalClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Users;
