import React, { useContext, useState } from 'react';
import Pagination from '../../components/Pagination/Pagination';
import './order.css';
import { useEffect } from 'react';
import { getAllOrders } from '../../api/order';
import { getAllUsers } from '../../api/users';
import { CSVLink } from 'react-csv';
import { Type } from 'react-bootstrap-table2-editor';
import Modal from 'react-modal';
import remove from '../../assets/Remove.svg';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { EcomContext } from '../../context/Context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import Loader from '../Loader/Loader';
const Order = () => {
  const [isLoading, setIsLoading] = useState(true);
  const {
    combineOrderData,
    setCombineOrderData,
    myProducts,
    setFetchAll,
    fetchAll,
  } = useContext(EcomContext);

  const [isFetch, setIsFetch] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('all');

  const order = [...combineOrderData];
  console.log('order', combineOrderData);

  const [allOrders, setAllOrders] = useState([]);
  const [userDetails, setUserDetails] = useState([]);

  const [modalVisible, setModalVisible] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({});
  const [newStatus, setNewStatus] = useState('');

  const [searchValue, setSearchValue] = useState('');
  const [orderLimitValue, setOrderLimitValue] = useState(10);
  const [orderDataset, setOrderDataset] = useState([]);

  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedOrderIdToDelete, setSelectedOrderIdToDelete] = useState('');

  // Function to open the delete confirmation modal
  const openDeleteModal = (orderId) => {
    setDeleteModalVisible(true);
    setSelectedOrderIdToDelete(orderId);
  };

  // Function to close the delete confirmation modal
  const closeDeleteModal = () => {
    setDeleteModalVisible(false);
    setSelectedOrderIdToDelete('');
  };

  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTypeFilter, setSelectedTypeFilter] = useState(true);

  const token = sessionStorage.getItem('token');
  const itemsPerPage = 20;
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    setCurrentPage(1); // Reset to the first page whenever the data changes
  }, [combineOrderData]);

  const getCurrentPageData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const filteredData = selectedTypeFilter ? filteredOrders : filteredOrder;
    return filteredData.slice(startIndex, endIndex);
  };

  const filteredOrders = order
    .filter((order) => {
      if (selectedStatus === 'all') {
        return true;
      } else {
        return order.status.toLowerCase() === selectedStatus.toLowerCase();
      }
    })
    .filter((order) => {
      const firstName = order.firstName || '';
      return firstName.toLowerCase().includes(searchTerm.toLowerCase());
    });

  const pagedOrders = filteredOrders.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleSearch = () => {
    setCurrentPage(1);
  };

  const handleStatusChange = (event) => {
    setNewStatus(event.target.value);
  };

  const handleSaveChanges = async () => {
    try {
      // make the PUT request to update the order status
      const response = await fetch(
        `https://api.pragatimitra.in/api/v1/orders/${selectedOrder._id}`,
        {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${token}`,
            token: `${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            status: newStatus,
          }),
        }
      );

      // check if the API call was successful
      if (!response.ok) {
        throw new Error('Failed to update order status');
      } else {
        setFetchAll(!fetchAll);
      }

      // close the modal once the API call is successful
      setModalVisible(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleActionClick = (order) => {
    setSelectedOrder(order);
    setNewStatus(order.status);
    setModalVisible(true);
  };

  const statusOptions = ['Pending', 'Confirm', 'Complete', 'Cancel'];

  const handleDeleteClick = async (orderId) => {
    const token = sessionStorage.getItem('accessToken');

    try {
      const response = await fetch(
        `https://api.pragatimitra.in/api/v1/orders/${orderId}`,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${token}`,
            token: `${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.ok) {
        // update the table data after successful deletion

        toast.success('Order deleted successfully');
        setFetchAll(!fetchAll);
        closeDeleteModal();
      } else {
        toast.error('Failed to delete order');
      }
    } catch (error) {
      console.error(error);
      toast.error('Error deleting order');
    }
  };
  useEffect(() => {
    const getAllOrdersAdmin = async () => {
      try {
        setIsLoading(true);
        const response = await getAllOrders();

        const data = await response.json();

        if (data.type === 'success') {
          setAllOrders([...data?.orders]);
          setIsLoading(false);
          // console.log("allOrders",allOrders)
        }
      } catch (error) {
        toast.error(error);
      }
    };
    const fetchAllUsers = async () => {
      try {
        setIsLoading(true);
        const repsonseAllUsers = await getAllUsers();
        const allUsers = await repsonseAllUsers.json();

        if (allUsers.type === 'success') {
          setUserDetails(allUsers.users);
          setIsLoading(false);
        }
      } catch (error) {
        toast.error(error);
      }
    };
    fetchAllUsers();
    getAllOrdersAdmin();
  }, [fetchAll]);

  // const filteredOrders = order.filter(order => {
  //   if (selectedStatus === 'all') {
  //     return true;
  //   } else {
  //     return order.status.toLowerCase() === selectedStatus.toLowerCase();
  //   }
  // });

  const filteredOrder = order.filter((orderItem) => {
    return (
      orderItem.firstName &&
      orderItem.firstName.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '500px',
      height: '363px',
    },
  };

  useEffect(() => {
    if (allOrders) {
      const _data = allOrders.map((item) => ({
        ORDER_ID: item._id,
        DATE: new Date(item.updatedAt).toDateString(),
        ADDRESS: item.address,
        MOBILE: item.mobile,
        PAYMENT_METHOD: item.paymentMethod,
        STATUS: item.status,
      }));

      if (_data) setOrderDataset(_data);
    }
  }, [allOrders]);

  const handleDownloadAllOrders = () => {
    // const downloadOrderData = allOrders.map((item) => {
    // })
  };

  // const handleSearch = () => {
  //   setCurrentPage(1); // Reset to the first page when performing a new search
  //   setSelectedTypeFilter(false); // Set the selectedTypeFilter to false to indicate searching by name
  // };

  const getStatusColor = (status) => {
    switch (status) {
      case 'Delivered':
        return '#5cb85c';
      case 'Processing':
        return '#f1c40f';
      case 'Shipped':
        return '#3498db';
      case 'pending':
        return '#005A9C';
      default:
        return '#e74c3c';
    }
  };

  const totalPages = Math.ceil(allOrders.length / itemsPerPage); // for example purposes only

  return (
    <div className="container mt-5">
      <h2 className="mb-4">Order List</h2>
      <div className="order-row mb-3 ms-2 me-2">
        <div className="order-search">
          <input
            type="text"
            placeholder="Search orders"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button onClick={handleSearch}>Search</button>
        </div>

        <div className="order-filter">
          <label htmlFor="status-filter">Status:</label>
          <select
            id="status-select"
            value={selectedStatus}
            onChange={(e) => {
              setSearchTerm('');
              setSelectedTypeFilter(true);
              setSelectedStatus(e.target.value);
            }}
          >
            <option value="all">All</option>
            <option value="pending">Pending</option>
            <option value="shipped">Confirm</option>
            <option value="delivered">Complete</option>
            <option value="in progress">Cancel</option>
          </select>
        </div>
        <div className="order-download">
          <button onClick={handleDownloadAllOrders}>
            <CSVLink
              data={orderDataset}
              filename="OrderData"
              className="csvLink"
              type={Type.Button}
              target="_blank"
            >
              <FontAwesomeIcon icon={faDownload} className="pe-1" />
              Download All Orders
            </CSVLink>
          </button>
        </div>
      </div>
      <div className="order-list">
        <div>
          <table className="mb-5">
            <thead>
              <tr>
                <th>Order ID</th>
                <th>Customer Name</th>
                <th>product</th>
                <th>Quantity</th>
                <th>Date</th>
                <th>Address</th>
                <th>Mobile</th>
                {/* <th>Payment Method</th> */}
                <th>Status</th>
                <th>Action</th>
                <th>Delete</th>
              </tr>
            </thead>

            <tbody>
              {isLoading ? (
                <Loader />
              ) : pagedOrders.length > 0 ? (
                pagedOrders.map((order) => (
                  <React.Fragment key={order._id}>
                    {order.products.map((product, index) => {
                      const matchedProduct = myProducts.products.find(
                        (item) => item._id === product.productId
                      );
                      const dateString = product.selectedDate
                        ? product.selectedDate
                        : null;
                      const date = new Date(dateString);
                      const options = {
                        year: '2-digit',
                        month: '2-digit',
                        day: '2-digit',
                      };
                      const formattedDate = date.toLocaleDateString(
                        'en-US',
                        options
                      );
                      console.log(formattedDate);
                      const productName = matchedProduct.title;
                      return (
                        <tr key={product._id}>
                          {index === 0 ? ( // Display user ID only for the first row
                            <td rowSpan={order.products.length}>
                              {order.userId}
                            </td>
                          ) : null}
                          {index === 0 ? ( // Display order ID only for the first row
                            <td rowSpan={order.products.length}>
                              {order.firstName && order.lastName
                                ? `${order.firstName} ${order.lastName}`
                                : '-'}
                            </td>
                          ) : null}
                          <td>{productName}</td>
                          <td className="text-center">{product.quantity}</td>
                          {
                            <td className="text-center">
                              {product.selectedDate
                                ? new Date(product.selectedDate).toLocaleString(
                                    'en-US',
                                    {
                                      year: 'numeric',
                                      month: '2-digit',
                                      day: '2-digit',
                                    }
                                  )
                                : '-'}
                            </td>
                          }
                          {index === 0 ? (
                            <td rowSpan={order.products.length}>
                              {order.address}
                            </td>
                          ) : null}
                          {index === 0 ? (
                            <td rowSpan={order.products.length}>
                              {order.mobile}
                            </td>
                          ) : null}
                          {index === 0 ? (
                            <td
                              rowSpan={order.products.length}
                              style={{ color: getStatusColor(order.status) }}
                            >
                              {order.status}
                            </td>
                          ) : null}

                          {index === 0 ? (
                            <td rowSpan={order.products.length}>
                              <button
                                onClick={() => handleActionClick(order)}
                                className="editContainer"
                              >
                                <FontAwesomeIcon
                                  icon={faEdit}
                                  className="editIcon"
                                />
                              </button>
                            </td>
                          ) : null}
                          {index === 0 ? (
                            <td rowSpan={order.products.length}>
                              <button
                                onClick={() => openDeleteModal(order._id)}
                                className="removeContainer"
                              >
                                <FontAwesomeIcon
                                  icon={faTrash}
                                  className="removeIcon"
                                />
                              </button>
                            </td>
                          ) : null}
                        </tr>
                      );
                    })}
                  </React.Fragment>
                ))
              ) : (
                <tr>
                  <td colSpan={9}>No orders found</td>
                </tr>
              )}
            </tbody>
          </table>
          <div>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </div>
        </div>

        <Modal isOpen={modalVisible} style={customStyles}>
          <div className="Modal">
            <div className="Modal__header">
              <h2>Update Order Status</h2>
              <button
                onClick={() => setModalVisible(false)}
                className="Modal__close"
              >
                &times;
              </button>
            </div>
            <div className="Modal__body">
              <p>Order ID: {selectedOrder._id}</p>
              <p>
                Current Status:{' '}
                <span style={{ color: getStatusColor(selectedOrder.status) }}>
                  {selectedOrder.status}
                </span>
              </p>
              <label htmlFor="new-status">New Status:</label>
              <select
                id="new-status"
                value={newStatus}
                onChange={handleStatusChange}
              >
                {statusOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
            <div className="Modal__footer">
              <button onClick={handleSaveChanges}>Save Changes</button>
              <button
                onClick={() => setModalVisible(false)}
                className="Modal__cancel"
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>
        {/* Delete Confirmation Modal */}
        <Modal isOpen={deleteModalVisible} style={customStyles}>
          <div className="Modal">
            <div className="Modal__header">
              <h2>Delete Confirmation</h2>
              <button onClick={closeDeleteModal} className="Modal__close">
                &times;
              </button>
            </div>
            <div className="Modal__body">
              <p>Are you sure you want to delete this order?</p>
            </div>
            <div className="Modal__footer">
              <button
                onClick={() => handleDeleteClick(selectedOrderIdToDelete)}
              >
                Yes
              </button>
              <button onClick={closeDeleteModal} className="Modal__cancel">
                No
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default Order;
