import React, { useContext, useState } from 'react'
  import './EditProduct.scss'
import { BASE_URL, IMG_URL } from '../../confiig';
import { updateProduct } from '../../api/product';
import { toast } from 'react-toastify';
import { EcomContext } from '../../context/Context';

const EditProduct = ({ productDetails, onEditClose,onUpdateProduct }) => {
  const { myCategory } = useContext(EcomContext)
  const AllCategory = [...myCategory?.category]
  console.log(productDetails)
  const currentDate = new Date().toLocaleDateString("en-CA");
  const [startDate, setStartDate] = useState(productDetails.startDate);
  const [endDate, setEndDate] = useState(productDetails.endDate);
  // ... other state variables and functions ...
  function formatDateForInput(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Ensure two digits
    const day = date.getDate().toString().padStart(2, '0'); // Ensure two digits
  
    // Return the formatted date string in yyyy-mm-dd format
    return `${year}-${month}-${day}`;
  }
  
  const handleStartDateChange = (event) => {
    // Update the startDate state when the Start Date input changes
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    // Update the endDate state when the End Date input changes
    setEndDate(event.target.value);
  };

  const [title, setTitle] = useState(productDetails.title);
  const [description, setDescription] = useState(productDetails.description);
  const [imageFile, setImageFile] = useState(productDetails.image);
  const [price, setPrice] = useState(productDetails.price);
  // const [quantity, setQuantity] = useState(productDetails.quantity)
  // const [updatedProduct,setUpdatedProduct]=useState({})
 
  const [categories, setCategories] = useState(productDetails.categories.join(', '))
 

  const handleProductNameChange = (event) => {
    setTitle(event.target.value);
  };

    const handleDescriptionChange = (event) => {
      setDescription(event.target.value);
    };

    const handlePriceChange = (event) => {
      setPrice(event.target.value);
    };

  // const handleQuantityChange = (event) => {
  //   setQuantity(event.target.value);
  // };
  const handleCategoryChange = (event) => {
    setCategories(event.target.value);
  };

    const handleImageChange = (event) => {
      setImageFile(event.target.result);
    };



 
  const handleSubmit = async (event) => {
    
    event.preventDefault();
    const editedProduct={
      "title":title,
      "description":description,
      "image":imageFile,
      "price":price,
      "categories":categories,
      "startDate":startDate,
      "endDate":endDate
    }
   

    const token = sessionStorage.getItem("token");

    const productData={
      id:productDetails._id,
      editedProduct
    }

    try {
      const response = await updateProduct(productData)
      const data = await response.json();
   
      toast.success(data.message)
      onUpdateProduct()
     
      onEditClose(false);
    } catch (error) {
      toast.error(error);
    }
  };


  return (
    <div className='edit-card'>
      <h1>Edit Product</h1>
      <form onSubmit={handleSubmit}>
        {/* <div className="form-group">
          <label htmlFor="image">Image</label>
          <input type="file" placeholder='upload Image' id="image" onChange={handleImageChange} value="" />
        </div> */}
        <div className="form-group  d-flex justify-content-between">
          <label htmlFor="image" >Image</label>
          <div >
            {imageFile ? (
              <img height={'50px'} width={'50px'} src={`${IMG_URL}/${imageFile}`} alt="Previous Imagee" />
            ) : null}
            <input
              type="file"
              placeholder="Upload Image"
              id="image"
              onChange={handleImageChange}
              value=""
            />
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="ProductName">Product Name</label>
          <input type="text" id='productName' placeholder='Product Name' value={title} onChange={handleProductNameChange} />
        </div>
        {/* <div className="form-group">
          <label htmlFor="ProductName">Category</label>
          <input type="text" id='category' placeholder='Category' value={categories} onChange={handleCategoryChange} />
        </div> */}
        <div className="form-group">
          <label htmlFor="category" className="form-label text-black">Category</label>
          <select
            className="form-select"
            id="category"
            name="category"
            value={categories}
            onChange={handleCategoryChange}
          >
            <option value="">Select Category</option>
            {AllCategory.map((category) => (
              <option key={category._id} value={category.title}>
                {category.title}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="description">Description</label>
          <textarea id="description" placeholder='Description' value={description} onChange={handleDescriptionChange} />
        </div>
        <div className="calendar__input-wrapper">
  <div>
    <span className="calendar__date-label">Start Date:</span>
    <input
      type="date"
      id="bookingDateFrom"
      className="calendar__input"
      min={currentDate}
      value={formatDateForInput(startDate)} // Format the startDate value for input
      onChange={handleStartDateChange}
    />
  </div>
</div>

<div className="calendar__input-wrapper">
  <div>
    <span className="calendar__date-label">End Date:</span>
    <input
      type="date"
      id="bookingDateTo"
      className="calendar__input"
      min={currentDate}
      value={formatDateForInput(endDate)} // Format the endDate value for input
      onChange={handleEndDateChange}
    />
  </div>
</div>

        <div className="price-qty">
        <div className="form-group">
            <label htmlFor="price">Price</label>
            <input type="text" id="price" placeholder='Price' value={price} onChange={handlePriceChange} />
          </div>
          {/* <div className="form-group">
            <label htmlFor="quantity">Quantity</label>
            <input type="number" id="quantity" placeholder='Quantity' value={quantity} onChange={handleQuantityChange} />
          </div> */}
        </div>
        <div className="form-buttons">
          <button type="submit">Submit</button>
          <button type="button" onClick={()=>onEditClose(false)}>Cancel</button>
        </div>
      </form>
    </div>
  )
}

  export default EditProduct