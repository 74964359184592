import React, { useContext } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { EcomContext } from '../context/Context';

const PrivateRoutes = () => {
  const { isLoggin } = useContext(EcomContext);

  return (
    // <Route {...rest} >
    //     {(props) => (
    //   isLoggin
    //     ? <Component {...props} />
    //     : <Navigate to='/signin' />
    // )} />
   
        
            isLoggin ? <Outlet/>
            :
            <Navigate to='/signin'/>
        
        
    
  );
};

export default PrivateRoutes;