import React, { createContext, useState, useEffect } from 'react';
import { getProducts } from '../api/product';
import { signin } from '../api/signin';
import { getCategories } from '../api/category';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Navigate } from 'react-router-dom';
import { getAllOrders } from '../api/order';
import { getAllUsers } from '../api/users';
import { getAdminProfile } from '../api/adminProfile';
import { useIdleTimer } from 'react-idle-timer';

export const EcomContext = createContext();

const EcomContextProvider = ({ children }) => {
  const initialLoginState = JSON.parse(sessionStorage.getItem('isLoggin'));
  const [myProducts, setMyProducts] = useState({});
  const [adminInfo, setAdminInfo] = useState({});
  const [combineOrderData, setCombineOrderData] = useState([]);
  // const [isLoggin, setIsLoggin] = useState(false)
  // const [signinDetails, setSignInDetails] = useState({});
  const [isLoggin, setIsLoggin] = useState(initialLoginState || false);
  const [myCategory, setMyCategory] = useState([]);
  const [fetchAll, setFetchAll] = useState(false);
  const [allOrders, setAllOrders] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [adminProfileInfo, setAdminProfileInfo] = useState([]);

  // const timeout = 60000 * 7;
  const [isIdle, setIsIdle] = useState(false);
  const handleOnActive = () => setIsIdle(false);
  const handleOnIdle = () => setIsIdle(true);

  const idleTimer = useIdleTimer({
    timeout: 30 * 10 * 1000,
    onActive: handleOnActive,
    onIdle: handleOnIdle,
  });

  useEffect(() => {
    if (isIdle && sessionStorage.getItem('token')) {
      logoutHandler();
    }
  }, [isIdle]);

  if (isLoggin) {
  }
  useEffect(() => {
    if (isLoggin) {
      const getProductAsync = async () => {
        await getProducts()
          .then((val) => val.json())
          .then((val) => setMyProducts(val));
      };
      // const getSignInDetails = async () => {
      //     await signin().then(val => val.json()).then(val => setSignInDetails(val));
      // };

      const getOrderAsync = async () => {
        await getAllOrders()
          .then((val) => val.json())
          .then((val) => setAllOrders([...val?.orders]));
      };
      const getUserAsync = async () => {
        await getAllUsers()
          .then((val) => val.json())
          .then((val) => setAllUsers([...val?.users]));
      };
      const getCategoryAsync = async () => {
        await getCategories()
          .then((res) => res.json())
          .then((data) => setMyCategory(data));
      };

      const getAdminProfileAsync = async () => {
        if (sessionStorage.getItem('adminId')) {
          await getAdminProfile()
            .then((res) => res.json())
            .then((data) => {
              if (data.type === 'success') {
                // console.log( data.data )
                setAdminProfileInfo(data?.data);
              }
            });
        }
      };
      getProductAsync();
      // getSignInDetails();
      getCategoryAsync();
      getOrderAsync();
      getUserAsync();
      getAdminProfileAsync();
    }
  }, [isLoggin, fetchAll]);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setFetchAll(fetch => !fetch)
  //   }, 2000)
  //   return () => clearInterval(interval)
  // }, [])

  // const login = async (payload) => {
  //    try {
  //     const response = await signin(payload)

  //     const data = response.json()

  //     if (data.type === "success") {
  //         localStorage.setItem('token', data.accessToken);
  //         // localStorage.setItem('token', data.accessToken);
  //         localStorage.setItem('userInfo', JSON.stringify(data));
  //         setUserInfo(data)
  //         localStorage.setItem('islogin', true);
  //         setIsLoggin(true);

  //     }
  //    } catch (error) {
  //     console.log(error)
  //    }
  // }
  useEffect(() => {
    if (allOrders?.length && allUsers?.length) {
      // console.log(allOrders, allUsers)
      let combinedOrderData = allOrders?.map((order) => {
        let order_ = allUsers?.find((o) => o?._id === order?.userId);
        // console.log(order_)
        if (!order_) {
          return {
            ...order,
          };
        } else {
          return {
            ...order,
            email: order_?.email,
            firstName: order_?.firstName,
            lastName: order_?.lastName,
            mobile: order_?.mobile,
          };
        }
      });
      setCombineOrderData(combinedOrderData);
    }
  }, [allOrders, allUsers, fetchAll]);

  // const login = async (email, password) => {
  //   try {
  //     const response = await signin(email, password);

  //     const data = await response.json();

  //     if (data.type === "success") {

  //       localStorage.setItem("token", data.accessToken);

  //       localStorage.setItem("adminInfo", JSON.stringify(data));

  //       localStorage.setItem('adminId', data._id)

  //       console.log(data)

  //       setAdminInfo(data);

  //       setIsLoggin(true);

  //       localStorage.setItem('isLoggin', true)

  //       return data;
  //     }
  //   } catch (error) {
  //     toast.error(error)
  //   }
  // }

  // const isLoggedIn = async () => {
  //   try {
  //     let adminInfo = localStorage.getItem("adminInfo");

  //     adminInfo = JSON.parse(adminInfo);

  //     if (adminInfo) {
  //       setAdminInfo(adminInfo)
  //       // setIsLoggin(true)
  //       // setIsLoggin(true);
  //       const storedIsLoggin = localStorage.getItem("isLoggin");

  //       const parsedIsLoggin = JSON.parse(storedIsLoggin);

  //       setIsLoggin(parsedIsLoggin || false);
  //     }
  //     else {
  //       setIsLoggin(false)
  //       setAdminInfo({})
  //     }

  //   } catch (e) {
  //     toast.error(e);
  //   }
  // };

  // const logoutHandler = () => {
  //   localStorage.removeItem("token");
  //   localStorage.removeItem("userInfo");
  //   localStorage.removeItem("isLoggin");
  //   localStorage.removeItem("adminId");
  //   setAdminInfo({});
  //   setIsLoggin(false);
  // };
  // useEffect(() => {
  //   isLoggedIn()
  // }, [])
  const login = async (email, password) => {
    try {
      const response = await signin(email, password);
      const data = await response.json();
      if (data.type === 'error') {
        return data;
      }
      if (data.type === 'success') {
        // Set expiration time to 30 seconds from now

        // Store token and user info in session storage
        sessionStorage.setItem('token', data.accessToken);
        sessionStorage.setItem('adminInfo', JSON.stringify(data));
        sessionStorage.setItem('adminId', data._id);
        // sessionStorage.setItem('expiration', expiration.toString());

        // console.log(data)

        setAdminInfo(data);
        setIsLoggin(true);

        // // Start a timer to clear session storage when token expires
        // const timeout = expiration - new Date().getTime();
        // setTimeout(() => {
        //   sessionStorage.removeItem("token");
        //   sessionStorage.removeItem("adminInfo");
        //   sessionStorage.removeItem("isLoggin");
        //   sessionStorage.removeItem("adminId");
        //   sessionStorage.removeItem("expiration");
        //   setAdminInfo({});
        //   setIsLoggin(false);
        // }, timeout);

        // Store isLoggin flag in session storage
        sessionStorage.setItem('isLoggin', true);

        return data;
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const isLoggedIn = async () => {
    try {
      let adminInfo = sessionStorage.getItem('adminInfo');

      adminInfo = JSON.parse(adminInfo);

      if (adminInfo) {
        setAdminInfo(adminInfo);
        const storedIsLoggin = sessionStorage.getItem('isLoggin');

        const parsedIsLoggin = JSON.parse(storedIsLoggin);

        setIsLoggin(parsedIsLoggin || false);

        // Check if session has expired
        // const expiration = sessionStorage.getItem("expiration");
        // if (expiration && new Date().getTime() > parseInt(expiration)) {
        //   sessionStorage.removeItem("token");
        //   sessionStorage.removeItem("adminInfo");
        //   sessionStorage.removeItem("isLoggin");
        //   sessionStorage.removeItem("adminId");
        //   sessionStorage.removeItem("expiration");
        //   setAdminInfo({});
        //   setIsLoggin(false);
        // }s
      } else {
        setIsLoggin(false);
        setAdminInfo({});
      }
    } catch (e) {
      toast.error(e);
    }
  };

  const logoutHandler = () => {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('adminInfo');
    sessionStorage.removeItem('isLoggin');
    sessionStorage.removeItem('adminId');
    sessionStorage.removeItem('expiration');
    sessionStorage.removeItem('accessToken');
    setAdminInfo({});
    setIsLoggin(false);
  };

  useEffect(() => {
    isLoggedIn();
  }, []);

  return (
    <EcomContext.Provider
      value={{
        myProducts,
        login,
        adminInfo,
        myCategory,
        isLoggin,
        fetchAll,
        adminProfileInfo,
        setCombineOrderData,
        setFetchAll,
        logoutHandler,
        setFetchAll,
        combineOrderData,
        idleTimer
      }}
    >
      {children}
    </EcomContext.Provider>
  );
};

export default EcomContextProvider;
