import React from 'react';
import './Pagination.scss';


const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const pageNumbers = [];

  // Generate page numbers based on total pages
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="pagination">
      {/* Show Previous button only when current page is not the first page */}
      {currentPage !== 1 && (
        <button className='previous' onClick={() => onPageChange(currentPage - 1)}>Prev</button>
      )}
  
      {/* Show page numbers */}
      {pageNumbers.map((number) => (
        <button
          key={number}
          onClick={() => onPageChange(number)}
          className={currentPage === number ? ' active' : 'page-item'}
        >
          {number}
        </button>
      ))}

      {/* Show Next button only when current page is not the last page */}
      {currentPage !== totalPages && (
        <button onClick={() => onPageChange(currentPage + 1)}>Next</button>
      )}
    </div>
  );
};

export default Pagination;






