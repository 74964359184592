import { BASE_URL } from "../confiig"
let token = null
// const token = sessionStorage.getItem('token');

const updateToken = () => {
  token = sessionStorage.getItem('token');
};

updateToken(); // Set the initial value of the token

export const getCategories = async () => {
  return fetch(`${BASE_URL}/categories`)
}

export const updateCategory = async (category) => {
  updateToken()
  const { id, title } = category;
  if (token !== null) {
    return fetch(`${BASE_URL}/categories/${id}`, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${token}`,
        'token': `${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ title })
    })
  } else {
    // Handle the case when the token is null
    return Promise.reject(new Error("Token is null"));
  }
}

export const addCategory = async (formData) => {
  updateToken()
  if (token !== null) {
    return fetch(`${BASE_URL}/categories`, {
      method: "POST",
      body: formData,
      headers: {
        "token": `${token}`
      },

    })
  } else {
    // Handle the case when the token is null
    return Promise.reject(new Error("Token is null"));
  }

}

export const deleteCategory = async (categoryId) => {
  updateToken()
  if (token !== null) {
    return fetch(`${BASE_URL}/categories/${categoryId}`, {
      method: "DELETE",
      headers: {
        "token": `${token}`,
        "Content-Type": "application/json"
      }
    })
  } else {
    // Handle the case when the token is null
    return Promise.reject(new Error("Token is null"));
  }

}