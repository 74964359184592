import { BASE_URL } from "../confiig" 

let token = null
// const token = sessionStorage.getItem('token');

const updateToken = () => {
  token = sessionStorage.getItem('token');
};

updateToken(); // Set the initial value of the token

export const getProducts = async () =>{
    return fetch(`${BASE_URL}/products`)
}

export const deleteProduct = async (id)=>{
  updateToken()
  if(token!==null){
    return fetch(`${BASE_URL}/products/${id}`, {
      method: "DELETE",
      headers: {
        "token": `${token}`,
        "Content-Type": "application/json",
      },
    })
  }else {
    // Handle the case when the token is null
    return Promise.reject(new Error("Token is null"));
  }
  
}

export const addProduct = async (addedProduct)=>{
 updateToken()
 if(token!==null){
  return fetch(`${BASE_URL}/products`,
  {
    method: "POST",
    headers: {
      "token": `${token}`,
    },
    body: addedProduct,
  }
);
 }
 else {
  // Handle the case when the token is null
  return Promise.reject(new Error("Token is null"));
}
}

export const updateProduct = async (productData)=>{
  updateToken()
   if(token!==null)
   {
    return fetch(
      `${BASE_URL}/products/${productData.id}`,
      {
        method: "PUT",
        headers: {
          "token": `${token}`,
         
          "Content-Type":"application/json"
        },
       body:JSON.stringify(productData.editedProduct)
      }
    );
   }
      else {
        // Handle the case when the token is null
        return Promise.reject(new Error("Token is null"));
      }
}