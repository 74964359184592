import React, { useState } from 'react';
import './AddProduct.scss';
import { useEffect } from 'react';
import { getCategories } from '../../api/category';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function AddProduct(props) {
  const currentDate = new Date().toLocaleDateString('en-CA');
  const [isChanged, setIsChanged] = useState(false);
  useEffect(() => {
    const getAllCategories = async () => {
      try {
        const response = await getCategories();

        const data = await response.json();
        
        if (data.type === 'success') {
          setGetAllCategories([...data?.category]);
        }
      } catch (error) {
        toast.error(error);
      }
    };
    getAllCategories();
  }, [isChanged]);
  // const [addedProduct,setAddedProduct]=useState({})

  // let token = localStorage.getItem('accessToken')

  const [image, setImage] = useState(null);
  const [imageName, setImageName] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [categories, setCategories] = useState();
  const [getAllCategories, setGetAllCategories] = useState([]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setImage(file);
    setImageName(file.name);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!image) {
      // Display a warning message or perform any necessary action
      toast.warn('Please select an image');

      return;
    }
    const formData = new FormData();

    formData.append('image', image);
    formData.append('title', title);
    formData.append('description', description);
    formData.append('price', price);
    formData.append('categories', categories);

    props.onAddSubmit(formData);
  };

  return (
    <div>
      
      <div className="card">
        <div className="product_header">
          <h3>Add Product</h3>
        </div>
        <form onSubmit={handleSubmit}>
          <div>
            <div className="col p-5">
              <div className="mb-3 text-start pb-3">
                <label
                  htmlFor="formGroupExampleInput"
                  className="form-label text-black"
                >
                  Upload
                </label>
                {/* <input
                  type="file"
                  className="form-control"
                  id="formGroupExampleInput"
                  name="image"
                  accept="image/*"
                  placeholder="Upload Image"
                  onChange={handleInputChange}
                /> */}
                <input
                  type="file"
                  className="form-control"
                  id="formGroupExampleInput"
                  name="image"
                  required
                  placeholder="Upload Image"
                  onChange={handleImageChange}
                />
                {imageName && <p>{imageName}</p>}
              </div>
              <div className="mb-3 text-start pb-3">
                <label
                  htmlFor="formGroupExampleInput2"
                  className="form-label text-black"
                >
                  Product Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="formGroupExampleInput2"
                  name="title"
                  placeholder="Enter Product Name"
                  onChange={(event) => setTitle(event.target.value)}
                />
              </div>
              <div className="form-floating text-start my-3 pb-3">
                <label
                  htmlFor="floatingTextarea"
                  className="form-label  text-black"
                >
                  Description
                </label>
                <br />
                <br />
                <textarea
                  className="form-control"
                  name="description"
                  placeholder="Description"
                  id="floatingTextarea"
                  onChange={(event) => setDescription(event.target.value)}
                ></textarea>
              </div>
              <div className="d-flex justify-content-start align-item-center">
                <div className="me-5">
                  <label
                    htmlFor="formGroupExampleInput2"
                    className="form-label text-black"
                  >
                    Price
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="formGroupExampleInput2"
                    name="price"
                    placeholder="Price"
                    onChange={(event) => setPrice(event.target.value)}
                  />
                </div>

                <div className="mb-5">
                  <label htmlFor="categories" className="form-label text-black">
                    Categories
                  </label>
                  <select
                    className="form-select"
                    id="categories"
                    name="categories"
                    onChange={(event) => setCategories(event.target.value)}
                  >
                    {/* <option>Select Category</option> */}
                    <option value="">Select Category</option>
                    {getAllCategories.map((category) => (
                      <>
                        
                        <option key={category._id} value={category.title}>
                          {category.title}
                        </option>
                      </>
                    ))}
                  </select>
                </div>
              </div>
              {/* <div className="calendar__container">
                <label htmlFor="bookingDate" className="calendar__label">Booking Dates</label>
                <div className="calendar__input-wrapper">
                  <div>
                    <span className="calendar__date-label">Start Date:</span>
                    <input
                      type="date"
                      id="bookingDateFrom"
                      className="calendar__input"
                      min={currentDate}
                    />
                  </div>
                </div>
              </div> */}

              {/* <div className="calendar__container">
                <label htmlFor="bookingDate" className="calendar__label">Booking Dates</label>
                <div className="calendar__input-wrapper">
                  <div>
                    <span className="calendar__date-label">Start Date:</span>
                    <input
                      type="date"
                      id="bookingDateFrom"
                      className="calendar__input"
                      min={currentDate}
                    />
                  </div>
                </div>
              </div> */}
              <div className="d-flex justify-content-start align-item-center">
                <button
                  type="submit"
                  className="btn btn-success text-white me-3"
                >
                  Submit
                </button>
                <button
                  type="button"
                  className="btn btn-danger text-white"
                  onClick={() => props.onAddClose(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddProduct;
