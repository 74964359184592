import React, { useState } from 'react'
import './EditUsers.scss'
import { BASE_URL } from '../../confiig';
import { updateProduct } from '../../api/product';
import { updateUser } from '../../api/users';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EditUsers = ({ userDetails, onEditClose, onUpdateUser }) => {

 
  const [firstName, setFirstName] = useState(userDetails.firstName);
  const [lastName, setLastName] = useState(userDetails.lastName);
  const [email, setEmail] = useState(userDetails.email);
  const [phoneNumber, setPhoneNumber] = useState(userDetails.mobile);
  // const [quantity, setQuantity] = useState(productDetails.quantity)
  // const [updatedProduct,setUpdatedProduct]=useState({})



  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  // const handleQuantityChange = (event) => {
  //   setQuantity(event.target.value);
  // };
  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value)
  };






  const handleSubmit = async (event) => {
    event.preventDefault();
    const editedUser = {
      "firstName": firstName,
      "lastName": lastName,
      "email": email,
      "mobile": phoneNumber,
   
    }


    const token = sessionStorage.getItem("token");

    const usersData = {
      id: userDetails._id,
      editedUser
    }

    try {
      const response = await updateUser(usersData)
      const data = await response.json();

      toast.success(data.message)
     
      onUpdateUser()

      onEditClose(false);
    } catch (error) {
      toast.error(error);
    }
  };


  return (
    <div className='edit-card'>
      <h1>Edit Product</h1>
      <form onSubmit={handleSubmit}>
        {/* <div className="form-group">
          <label htmlFor="image">Image</label>
          <input type="file" placeholder='upload Image' id="image" onChange={handleImageChange} value="" />
        </div> */}
        <div className="form-group">
          <label htmlFor="FirstName">First Name</label>
          <input type="text" id='FirstName' value={firstName} onChange={handleFirstNameChange} placeholder='First Name'  />
        </div>
        <div className="form-group">
          <label htmlFor="LastName">Last Name</label>
          <input type="text" id='LastName' value={lastName} onChange={handleLastNameChange} placeholder='Last Name'  />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <textarea id="email" value={email} onChange={handleEmailChange} placeholder='Email'  />
        </div>
        
          <div className="form-group">
            <label htmlFor="phone">Phone Number</label>
            <input type="text" id="phone" value={phoneNumber} onChange={handlePhoneNumberChange} placeholder='Phone Number'   />
          </div>
          {/* <div className="form-group">
            <label htmlFor="quantity">Quantity</label>
            <input type="number" id="quantity" placeholder='Quantity' value={quantity} onChange={handleQuantityChange} />
          </div> */}
        <div className="form-buttons">
          <button type="submit">Submit</button>
          <button type="button" onClick={() => onEditClose(false)}>Cancel</button>
        </div>
      </form>
    </div>
  )
}

export default EditUsers