// import React, { useContext, useState } from 'react';
// import classes from './AdminProfile.module.css';
// import { EcomContext } from '../../context/Context';
// import Modal from "react-modal";
// import EditProfile from './EditProfile';
// import { OnAdminEditProfile } from '../../api/adminProfile';
// import admin from '../../assets/administrator.png'
// import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

// const AdminProfile = () => {
//     const { adminProfileInfo, setFetchAll, fetchAll } = useContext(EcomContext);
//     // console.log(adminProfileInfo)
//     const { email, firstName, lastName, mobile, isAdmin } = adminProfileInfo
//     const [showModal, setShowModal] = useState(false);

//     const handleShowModal = () => {
//         setShowModal(true);
//     };

//     const handleCloseModal = () => {
//         setShowModal(false);
//     };

//     const handleSubmit = (e) => {
//         e.preventDefault();
//         // handle form submit here
//         handleShowModal();

//     };
//     const onProfileSubmit = async (data) => {
//         console.log("profile  data",data)

//         const response = await OnAdminEditProfile(data)

//         const responseData = await response.json()

//         if (responseData.type === "success") {
//             toast.success(responseData.message)
//             handleCloseModal()
//             setFetchAll(!fetchAll)  
//         }
//     }

//     return (
//         <div className={classes['user-card']}>
//             <div className={classes[`user-card-header`]}>
//                 <div className={classes["user-card-image-container"]}>
//                     <img src={admin} alt="User" className={classes["user-card-image"]} />
//                 </div>
//                 <div>
//                     <div className={classes["user-card-name"]}>Admin</div>
//                     <div className={classes["user-card-label"]}>Name:</div>
//                     <span className={classes["user-card-value"]}>{`${firstName} ${lastName}`}</span>
//                     <div className={classes["user-card-label"]}>Email:</div>
//                     <div className={classes["user-card-value"]}>{email}</div>
//                     <div className={classes["user-card-label"]}>Phone:</div>
//                     <div className={classes["user-card-value"]}>{mobile}</div>
//                     <div className={classes["user-card-label"]}>Role:</div>
//                     <div className={classes["user-card-value"]}>{isAdmin ? 'Admin' : ''}</div>
//                 </div>
//             </div>
//             <div className={classes["user-card-footer"]}>
//                 <button className={classes["user-card-button"]} onClick={handleSubmit}>Edit</button>
//             </div>
//             <Modal
//                 className="Modal"
//                 overlayClassName="Overlay"
//                 isOpen={showModal}
//                 onRequestClose={() => setShowModal(false)}
//             >
//                 {/* <AddProduct onAddClose={addClose} onAddSubmit={handleAddSubmit}  /> */}
//                 <EditProfile isFirstName={firstName} isLastName={lastName} onSubmit={onProfileSubmit} onClose={() => handleCloseModal()} isEmail={email} isPhone={mobile} isRole={isAdmin ? 'Admin' : ''} />
//             </Modal>
//         </div>
//     );
// };

// export default AdminProfile;


import React, { useContext, useEffect, useState } from 'react';
import classes from './AdminProfile.module.css';
import { EcomContext } from '../../context/Context';
import Modal from "react-modal";
import EditProfile from './EditProfile';
import { OnAdminEditProfile } from '../../api/adminProfile';
import admin from '../../assets/administrator.png'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../Loader/Loader';
import { IMG_URL } from '../../confiig';

const AdminProfile = () => {
    const { adminProfileInfo, setFetchAll, isLoggin, fetchAll } = useContext(EcomContext);
    useEffect(() => {
        console.log(adminProfileInfo, "adminProfileInfo")
    })
    const [isLoading, setIsLoading] = useState(false)
    useEffect(() => {
        setIsLoading(true)
        setTimeout(() => {
            setIsLoading(false)
        }, 400)
    }, [fetchAll])
    const [showModal, setShowModal] = useState(false);


    const handleShowModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        handleShowModal();
    };

  

    if (!adminProfileInfo) {
        return <div>Loading...</div>;
    }

   

    const { email, firstName, lastName, mobile, isAdmin,image } = adminProfileInfo;
   

    return (
        <div className={classes['user-card']}>
            <div className={classes[`user-card-header`]}>
                <div className={classes["user-card-image-container"]}>
                    <img src={`${IMG_URL}/${adminProfileInfo.image}`} alt={adminProfileInfo.image} className={classes["user-card-image"]} />
                </div>
                {isLoading ? <Loader /> : <div>
                    <div className={classes["user-card-name"]}>Admin</div>
                    <div className='d-flex'>
                        <div className={classes["user-card-label"]}>Name:</div>
                        <span className={classes["user-card-value"]}>{`${firstName} ${lastName}`}</span>
                    </div>
                    <div className='d-flex'>
                        <div className={classes["user-card-label"]}>Email:</div>
                        <div className={classes["user-card-value"]}>{email}</div>
                    </div>
                    <div className='d-flex'>
                        <div className={classes["user-card-label"]}>Phone:</div>
                        <div className={classes["user-card-value"]}>{mobile}</div>
                    </div>
                    <div className='d-flex'>
                        <div className={classes["user-card-label"]}>Role:</div>
                        <div className={classes["user-card-value"]}>{isAdmin ? 'Admin' : ''}</div>
                    </div>




                </div>}
            </div>
            <div className={classes["user-card-footer"]}>
                <button className={classes["user-card-button"]} onClick={handleSubmit}>Edit</button>
            </div>
            <Modal
                className="Modal-admin"
                overlayClassName="Overlay-admin"
                isOpen={showModal}
                onRequestClose={() => setShowModal(false)}
            >
                <EditProfile 
                isFirstName={firstName} 
                isLastName={lastName} 
                onFetchAll={()=>setFetchAll((data)=>!data)}  
                onClose={() => handleCloseModal()} 
                isEmail={email} 
                isPhone={mobile} 
                isRole={isAdmin ? 'Admin' : ''}
                isImage={image}

                // productDetails={editedProductDetails}
                // onEditClose={editClose}
                // myCategory={myCategory}
                // onEditSubmit={onEditProductSubmit}
                onUpdateProduct={() => setFetchAll((fetch)=>!fetch)}
                />
            </Modal>
        </div>
    );
};

export default AdminProfile;

