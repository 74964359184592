import React from 'react'
import Header from '../../components/Header/Header'
import Sidebar from '../../components/Sidebar/SideBar'
// import './AdminHome.scss'

const AdminHome = (props) => {
  return (
    <div>
      <Header/>
        <Sidebar/>
    </div>
  )
}

export default AdminHome