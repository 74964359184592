import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header/Header';
// import { AuthContext } from '../../context/AuthContext';
import './Signin.scss';
import { BASE_URL } from '../../confiig';
import { signin } from '../../api/signin';
import { EcomContext } from '../../context/Context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Signin = () => {
  const { login } = useContext(EcomContext);
  // console.log(userInfo)

  const navigate = useNavigate();
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  // const { login, isLoggedin, isAuthenticated,userInfo } = useContext(AuthContext);
  // const [error, setError] = useState('');

  const handlePhoneChange = (event) => {
    const inputValue = event.target.value.replace(/[^0-9]/g, '');
    setPhone(inputValue);
    setPhoneError('');
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setPasswordError('');
  };

  const handleSignIn = async () => {
    // if (!validatePhone(phone)) {
    //   setPhoneError("Please enter a valid email address");
    //   return;
    // }

    if (!password || password.length < 6) {
      setPasswordError('Password must be at least 6 characters long');
      return;
    }

    const data = {
      mobile: phone,
      password,
    };
    console.log('sign in', data);
    try {
      const responseSignInData = await login(data);

      // const SignInData = await responseSignInData.json()

      console.log('res', responseSignInData);
      // window.console.log(data)
      if (responseSignInData.type === 'error') {
        toast.error(responseSignInData.message);
      }
      if (responseSignInData.type === 'success') {
        // Store the access token in local storage
        sessionStorage.setItem('accessToken', responseSignInData.accessToken);
        // window.console.log(data.accessToken)

        // Clear the form fields and navigate to the home page
        setPhone('');
        setPassword('');
        sessionStorage.setItem('token', responseSignInData.accessToken);
        navigate('/adminhome');
      }
    } catch (error) {
      console.log(error);
      toast.error('An error occurred. Please try again later.');
    }
  };
  // const validatePhone = (phone) => {
  //   const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //   return emailPattern.test(phone);
  // };
  const handleShowPassword = () => setShowPassword(!showPassword);

  return (
    <div>
      <Header />
      <div className="signin-form">
        <div className="container-fluid loginContainer mt-5">
          <div className="row">
            <div className="col p-5 formBackground">
              <h1 className="text-center text-black">Login</h1>
              <div className="mb-3 text-start">
                <label
                  htmlFor="formGroupExampleInput"
                  className="form-label text-white"
                >
                  Enter your Phone Number
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="formGroupExampleInput"
                  placeholder="Enter your Phone Number"
                  value={phone}
                  pattern="[0-9]{10}"
                  maxLength={10}
                  onChange={handlePhoneChange}
                />
                {phoneError && <span className="text-white">{phoneError}</span>}
              </div>
              <div className="mb-3 text-start">
                <label
                  htmlFor="formGroupExampleInput2"
                  className="form-label text-white"
                >
                  Password
                </label>
                <input
                  type={showPassword ? 'text' : 'password'}
                  className="form-control"
                  id="formGroupExampleInput2"
                  placeholder="Enter Password"
                  value={password}
                  onChange={handlePasswordChange}
                />
                <FontAwesomeIcon
                  icon={showPassword ? faEyeSlash : faEye}
                  className="eye-icon"
                  onClick={handleShowPassword}
                />
                {passwordError && (
                  <span className="text-white">{passwordError}</span>
                )}
              </div>
              <p className="forgotPassword">Forgot Password ?</p>
              <div className="">
                <button
                  type="button"
                  className="btn btn-secondary w-100 text-white"
                  onClick={handleSignIn}
                >
                  Sign In
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signin;
