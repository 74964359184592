import React, { useEffect, useState } from 'react';

import 'react-toastify/dist/ReactToastify.css';

import { Pie } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import { Bar } from 'react-chartjs-2';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Dashboard.css';
import { useContext } from 'react';
import { EcomContext } from '../../context/Context';
import Loader from '../Loader/Loader';
import {
  getAdminBestSellWeekly,
  getAdminOrderCount,
  getAllUserCount,
} from '../../api/dashboard';

function Dashboard() {
  const [pieChartdata, setPieChartData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [userCount, setUserCount] = useState([]);
  const [adminOrderCount, setAdminOrderCount] = useState([]);
  const [adminBestSellWeeklyCount, setAdminBestSellWeeklyCount] = useState([]);
  const data = useContext(EcomContext);
  const { fetchAll, setFetchAll, isLoggin, combineOrderData } = data;
  const totalCount = userCount.reduce((acc, item) => acc + item.total, 0);
  useEffect(() => {
    const data = setFetchAll((fetch) => !fetch);

    return () => clearInterval(data);
  }, [setFetchAll]);

  useEffect(() => {}, [adminOrderCount]);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const response = await getAdminBestSellWeekly();
        const data = await response.json();
        console.log('piechart', data);
        setPieChartData(data.data);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      }
    }
    fetchData();
  }, [fetchAll]);

  // Preprocess the data to count the number of occurrences for each product ID
  const productCounts = {};
  if (adminOrderCount.dataLastWeek && adminOrderCount.dataLastWeek.length > 0) {
    adminOrderCount.dataLastWeek.forEach((obj) => {
      const productId = obj._id;
      if (productCounts[productId]) {
        productCounts[productId] += 1;
      } else {
        productCounts[productId] = 1;
      }
    });
  }

  // Generate the chart data using the processed counts

  const uniqueCounts = {};
  const chartDatas = {
    labels: [],
    datasets: [
      {
        label: 'Count',
        data: [],
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
      },
    ],
  };

  Object.entries(productCounts).forEach(([productId, count]) => {
    const uniqueCount = `${count}-${productId}`;
    if (uniqueCounts[uniqueCount]) {
      uniqueCounts[uniqueCount] += 1;
    } else {
      uniqueCounts[uniqueCount] = 1;
      chartDatas.labels.push(productId);
      chartDatas.datasets[0].data.push(count);
    }
  });

  // Use the options when creating the chart

  const chartData = {
    labels: pieChartdata?.map((item) => item._id),
    datasets: [
      {
        data: pieChartdata.map((item) => item.count),
        backgroundColor: [
          '#FF6384',
          '#36A2EB',
          '#FFCE56',
          '#0074D9',
          '#B10DC9',
          '#FF851B',
        ],
        hoverBackgroundColor: [
          '#FF6384',
          '#36A2EB',
          '#FFCE56',
          '#0074D9',
          '#B10DC9',
          '#FF851B',
        ],
      },
    ],
  };
  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  useEffect(() => {
    const getUserCount = async () => {
      try {
        setIsLoading(true);
        const response = await getAllUserCount();
        // if (!response.ok) {
        //   window.location.reload()
        // }
        const data = await response.json();
        //  console.log("response", data);

        if (data.type === 'success') {
          setUserCount(data.data);

          setIsLoading(false);
          // console.log("userCount",userCount)
        }
      } catch (error) {
        toast.error(error);
        setIsLoading(false);
      }
    };
    if (isLoggin && sessionStorage.getItem('token') !== null) {
      getUserCount();
    }
  }, [isLoggin]);

  useEffect(() => {
    const getOrderCount = async () => {
      try {
        setIsLoading(true);
        const response = await getAdminOrderCount();
        // if (!response.ok) {
        //   window.location.reload()
        // }
        const data = await response.json();
        console.log('admin', data);

        if (data.type === 'success') {
          setAdminOrderCount(data.data);
          setIsLoading(false);
          // console.log("adminOrderCount", adminOrderCount)
        }
      } catch (error) {
        toast.error(error);
        setIsLoading(false);
      }
    };
    if (isLoggin && sessionStorage.getItem('token') !== null) {
      getOrderCount();
    }
  }, [isLoggin]);
  useEffect(() => {
    console.log('adminOrderCount', adminOrderCount);
  }, [adminOrderCount]);
  useEffect(() => {
    const getAdminBestSellWeeklyCount = async () => {
      try {
        setIsLoading(true);
        const response = await getAdminBestSellWeekly();
        // if (!response.ok) {
        //   window.location.reload()
        // }
        const data = await response.json();

        // console.log(data)

        if (data.type === 'success') {
          setAdminBestSellWeeklyCount(data.data);
          setIsLoading(false);
          console.log('adminBestSellWeeklyCount', adminBestSellWeeklyCount);
        }
      } catch (error) {
        toast.error(error);
        setIsLoading(false);
      }
    };
    if (isLoggin && sessionStorage.getItem('token') !== null) {
      getAdminBestSellWeeklyCount();
    }
  }, [isLoggin]);

  const getStatusColor = (status) => {
    switch (status) {
      case 'Delivered':
        return '#5cb85c';
      case 'Processing':
        return '#f1c40f';
      case 'Shipped':
        return '#3498db';
      case 'pending':
        return '#005A9C';
      default:
        return '#e74c3c';
    }
  };

  return (
    <div className="container">
      <div className="text-start mt-5 ps-5">
        <h2> Dashboard</h2>
      </div>

      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="main-content">
            <div className="row">
              <div className="col-sm-4">
                <div className="dashboard_card">
                  <div className="card-body">
                    <h5 className="card-title">Total Orders</h5>
                    <p className="card-text">{totalCount}</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="dashboard_card">
                  <div className="card-body">
                    <h5 className="card-title">Monthly Orders</h5>
                    <p className="card-text">
                      {Math.floor(adminOrderCount.Yearly / 12)}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="dashboard_card">
                  <div className="card-body">
                    <h5 className="card-title">Yearly Orders</h5>
                    <p className="card-text">{adminOrderCount.Yearly}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between row ps-5 pe-5 ">
            <div className="shadow p-3 mb-5 bg-white rounded col-6 ">
              <h4> weekly sales</h4>
              {/* <p className="card-text">{adminOrderCount.Weekly}</p> */}
              <Bar data={chartDatas} />
            </div>
            <div className="shadow p-3 mb-5 bg-white rounded col-6">
              <h4> best selling product</h4>
              {/* {
            adminBestSellWeeklyCount?.map((item) => {
              return <p className="card-text">{item.count}</p>
            })
          } */}
              {/* <div className="piechart">
            <Pie data={chartData} />
          </div> */}

              {/* <div className="pie-chart-container">
            <div className="chart-wrapper">
              <Pie data={chartData} options={options} />
            </div>
            <div className="labels-wrapper">
              {chartData.labels.map((label, index) => (
                <div key={index} className="label">
                  <span
                    className="color"
                    style={{
                      backgroundColor:
                        chartData.datasets[0].backgroundColor[index],
                    }}
                  ></span>
                  <span className="text">{label}</span>
                </div>
              ))}
            </div>
          </div> */}
              <div className="pie-chart-container">
                {chartData && chartData.labels.length ? (
                  <>
                    <div className="chart-wrapper">
                      <Pie data={chartData} options={options} />
                    </div>
                    <div className="labels-wrapper">
                      {chartData.labels.map((label, index) => (
                        <div key={index} className="label">
                          <span
                            className="color"
                            style={{
                              backgroundColor:
                                chartData.datasets[0].backgroundColor[index],
                            }}
                          ></span>
                          <span className="text">{label}</span>
                        </div>
                      ))}
                    </div>
                  </>
                ) : (
                  <div className="text-start noBestSell">
                    {' '}
                    <p>No best selling product data available.</p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div>
            <div className="recent-orders">
              <h2>Recent Orders</h2>
              <table>
                <thead>
                  <tr>
                    <th>Order ID</th>
                    <th>Customer Name</th>
                    <th>Phone Number</th>
                    <th>Order Amount</th>
                    <th>Status</th>
                  </tr>
                </thead>

                {combineOrderData.map((item, index) => (
                  <tbody key={index}>
                    <tr>
                      <td>{item._id}</td>

                      <td>{`${item.firstName} ${item.lastName}`}</td>

                      <td>{item.mobile}</td>

                      <td>{item.amount}</td>

                      <td
                        className="text-start"
                        style={{ color: getStatusColor(item.status) }}
                      >
                        {item.status}
                      </td>
                    </tr>
                  </tbody>
                ))}
              </table>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Dashboard;
