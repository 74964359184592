import React, { useState, useEffect, useContext } from "react";

import edit from "../../assets/edit.svg";
import Modal from "react-modal";
import remove from "../../assets/Remove.svg";
import "./CategoryPage.scss";
import Pagination from "../../components/Pagination/Pagination";
import { BASE_URL, IMG_URL } from "../../confiig";
import {
  addCategory,
  deleteCategory,
  getCategories,
  updateCategory,
} from "../../api/category";
import { updateProduct } from "../../api/product";
import { EcomContext } from "../../context/Context";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../Loader/Loader";

// const URL=process.env.REACT_APP_BASE_URL
function CategoryPage(data) {
  const [isLoading, setIsLoading] = useState(false)
  // let category;
  const categoryContext = useContext(EcomContext);
  const { fetchAll, setFetchAll, myCategory } = categoryContext;
  // console.log("category",{fetchAll,setFetchAll,myCategory})
  // console.log("data", categoryContext);
  // let token = localStorage.getItem("token");
  const [showModal, setShowModal] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const openModal = (id) => {
    setSelectedItemId(id);
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };
    
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [addModalIsOpen, setAddModalIsOpen] = useState(false);
  const [editedCategoryName, setEditedCategoryName] = useState("");
  const [addCategoryName, setAddCategoryName] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [imageError, setImageError] = useState("")
  const [editedCategoryId, setEditedCategoryId] = useState(null);
  const [categories, setCategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  // const [filteredData, setFilteredData] = useState([]);
  const token = sessionStorage.getItem('token');
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };
  const [isChanged, setIsChanged] = useState(false);
  // const [count, setCount] = useState(0);

  useEffect(() => {
    const getAllCategories = async () => {
      try {
        setIsLoading(true)
        // const response = await getCategories()

        const data = myCategory;
        // console.log(data)
        if (data.type === "success") {
          setCategories([...data?.category]);
          setIsLoading(false)

          // console.log("categories",categories)
        }
      } catch (error) {
        toast.error(error);
        setIsLoading(false)

      }
    };
    getAllCategories();
  }, [isChanged, myCategory]);

  const handleAddCategory = (name) => {
    // Open the edit popup
    setAddModalIsOpen(true);
  };

  const handleEditCategory = (categoryId) => {
    // Find the category object in the categories list
    const categoryToEdit = categories.find(
      (category) => category._id === categoryId
    );

    // Set the edited category name and ID

    setEditedCategoryName(categoryToEdit.title);
    setEditedCategoryId(categoryId);

    // Open the edit popup
    setEditModalIsOpen(true);
  };
  const handleSaveEdit = async () => {
    const updatedCategory = { title: editedCategoryName, id: editedCategoryId };
    try {
      setIsLoading(true)
      const response = await updateCategory(updatedCategory);
      const data = await response.json();
      // console.log(data);
      if (data.type === "success") {
        setIsChanged(!isChanged);
        setFetchAll(!fetchAll);
        toast.success(`${data.updatedCategories.title} ${data.message}`);
        setIsLoading(false)
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false)
    } finally {
      setIsLoading(false)
    }

    // Close the edit popup
    setEditModalIsOpen(false);
    setEditedCategoryName(updatedCategory);
    // console.log(editedCategoryName);
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setImageFile(file);
    setImageError('')
  };

  // const handleSaveAdd = async () => {
  //   try {
  //     const formData = new FormData();
  //     formData.append("title", addCategoryName);
  //     console.log("",addCategoryName)
  //     formData.append("image", imageFile);
  //     console.log("", imageFile)
  //     console.log(formData, "response")
  //     const response = await addCategory({ formData });

  //     const data = await response.json();


  //     if (data.type === "success") {
  //       console.log("data",data)
  //       toast.success(data.message);
  //       setIsChanged(!isChanged);
  //       setFetchAll(!fetchAll);
  //     }
  //   } catch (error) {
  //     toast.error(error);
  //   }


  //   setAddModalIsOpen(false);

  //   setAddCategoryName("");
  //   setImageFile("")
  // };

  const handleSaveAdd = async () => {
    if (!imageFile) {
      setImageError('please select an Image')
      toast.error('please select an Image')
      return
    }
    try {
      setIsLoading(true)
      if (!imageFile) {
        // Display a warning message
        toast.warn('Please select an image');
        setIsLoading(false);
        return;
      }
      const formData = new FormData();
      formData.append("title", addCategoryName);
      formData.append("image", imageFile);

      const response = await fetch("https://api.pragatimitra.in/api/v1/categories/", {
        method: "POST",
        body: formData,
        headers: {
          'token': `${token}`,
        }


      });

      const data = await response.json();

      if (data.type === "success") {
        console.log("data", data)
        toast.success(data.message);
        setIsChanged(!isChanged);
        setFetchAll(!fetchAll);
        setIsLoading(false)
      }
    } catch (error) {
      toast.error(error.message);
      setIsLoading(false)
    } finally {
      setIsLoading(false)

    }

    setAddModalIsOpen(false);
    setAddCategoryName("");
    setImageFile("");

  };



  const handleDeleteCategory = async (categoryId) => {

    // console.log(categoryId);
    try {
      setIsLoading(true)
      const response = await deleteCategory(categoryId);

      const data = await response.json();
      console.log(data);

      if (data.type === "success") {
        setIsChanged(!isChanged);
        setFetchAll(!fetchAll);
        toast.success(data.message);
        setIsLoading(false)
        closeModal(); 
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false)
    } finally {
      setIsLoading(false)
    }

    // setCategories(updatedCategories);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  // const [itemCount, setItemCount] = useState(0);

  // const [displayedItems, setDisplayedItems] = useState(items);
  const totalPages = Math.ceil(categories.length / itemsPerPage); // for example purposes only 
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

 
 const getCurrentPageData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return categories.slice(startIndex, endIndex);
  };


  
  useEffect(() => {
    getCurrentPageData();
  }, [isChanged, fetchAll]);

  const filteredCategories = getCurrentPageData().filter(item => {
    return item.title && item.title.toLowerCase().includes(searchTerm.toLowerCase());
  });

  return (
    <div className="product_card">
      <div className="product_header">
        <h3>Category List</h3>
      </div>

      <div className="d-flex justify-content-between align-item-center category-header">
        <div className="order-search">
          <input
            type="text"
            placeholder="Search orders"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button>Search</button>

        </div>
        <div className="addItembtn">
          <button
            type="button"
            className="btn btn-success"
            onClick={handleAddCategory}
          >
            Add Category
          </button>
        </div>
      </div>

      <div>
        <table>
          <thead>
            <tr>
              <th>Category Id</th>
              <th>Image</th>
              <th>Category Name</th>

              <th>Edit</th>
              <th>Remove</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? <Loader />
              :
              filteredCategories.map((item) => (
                <tr key={item._id}>
                  <td>{item._id}</td>
                  <td><img src={`${IMG_URL}/${item.image}`} alt={item.image} /></td>
                  <td>{item.title}</td>

                  <td>
                    <button
                      className="editIcon"
                      onClick={() => handleEditCategory(item._id)}
                    >
                      {" "}
                      <FontAwesomeIcon icon={faEdit} className="editIcon" />{" "}
                    </button>
                  </td>
                  <td>
  <button
    className="removeIcon"
    onClick={() => openModal(item._id)}
  >
    <FontAwesomeIcon icon={faTrash} className="removeIcon " />
  </button>
</td>
{/* Confirmation Modal */}
<Modal
  isOpen={showModal}
  onRequestClose={closeModal}
  style={customStyles}
>
  <div className="modal-header">
    <h2>Confirm Delete</h2>
  </div>
  <div className="modal-body">
    <p>Are you sure you want to delete this item?</p>
  </div>
  <div className="modal-footer">
  <button className="yesButton" onClick={() => handleDeleteCategory(item._id)}>
    Yes
  </button>
  <button className="noButton" onClick={closeModal}>
    No
  </button>
  </div>
</Modal>


                </tr>
              ))}
          </tbody>
        </table>
        <div>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
      <Modal
        isOpen={editModalIsOpen}
        onRequestClose={() => setEditModalIsOpen(false)}
        style={customStyles}
      >
        <div className="modal-header">
          <h2>Edit Category</h2>
        </div>
        <div className="modal-body">
          <label htmlFor="edited-category-title">Title</label>
          <input
            id="edited-category-title"
            type="text"
            value={editedCategoryName}
            onChange={(event) => setEditedCategoryName(event.target.value)}
          />

        </div>
        <div className="modal-footer">
          <button
            className="cancel-button"
            onClick={() => setEditModalIsOpen(false)}
          >
            Cancel
          </button>
          <button className="save-button" onClick={handleSaveEdit}>
            Save
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={addModalIsOpen}
        onRequestClose={() => setAddModalIsOpen(false)}
        style={customStyles}
      >
        <div className="modal-header">
          <h2>Add Category</h2>
        </div>
        <div className="modal-body">
          <label htmlFor="category-title">Title</label>
          <input
            id="category-title"
            type="text"
            // value={addCategoryName}
            onChange={(event) => setAddCategoryName(event.target.value)}
          />
          <label htmlFor="category-image">Image</label>
          <input id="category-image" type="file" onChange={handleFileChange} required />
          {imageError && <p className="error-image-message">{imageError}</p>}
        </div>
        <div className="modal-footer">
          <button
            className="cancel-button"
            onClick={() => setAddModalIsOpen(false)}
          >
            Cancel
          </button>
          <button className="save-button" onClick={handleSaveAdd}>
            Save
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default CategoryPage;