
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  // Link,
  Navigate,
  useNavigate
} from "react-router-dom";
// import { AuthProvider,AuthContext } from './context/AuthContext';
import Order from './pages/Order/order';
// import AddProduct from "../src/pages/AddProduct/AddProduct";
// import Signin from './pages/Signin/Signin';
import Dashboard from './pages/dashboard/dashboard';
// import AddProduct from './pages/AddProduct/AddProduct';
// import Product from "../src/pages/AddProduct/Product";
import AdminHome from './pages/AdminHome/AdminHome'
import Signin from './pages/Signin/Signin';
import EditProfile from './pages/EditProfile/EditProfile';
import EcomContextProvider, { EcomContext } from "./context/Context";
// import PrivateRoutes from './PrivateRoute/PrivateRoute';
// import SideBar from './components/Sidebar/SideBar';
// import PrivateRoute from './PrivateRoute/PrivateRoute';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PrivateRoutes from './PrivateRoute/PrivateRoute';
import { useContext, useEffect, useState } from 'react';

function App() {
  const { isLoggin } = useContext(EcomContext)



  // console.log(data)
  return (
    <>
      <Router>
        <Routes>
          <Route path='/signin' element={<Signin />} />
          <Route element={<PrivateRoutes />}>
            {/* <Route></Route> */}
            <Route path='/adminhome' element={<AdminHome />} />
            <Route path='/editprofile' element={<EditProfile />} />
            <Route path='/order' element={<Order />} />
            <Route path='/dashboard' element={<Dashboard />} />
          </Route>
          {/* <PrivateRoute path='/adminhome' element={<AdminHome />} />
          <PrivateRoute path='/editprofile' element={<EditProfile />} />
          <PrivateRoute path='/order' element={<Order />} />
          <PrivateRoute path='/dashboard' element={<Dashboard />} /> */}
          <Route path='*' element={
            isLoggin ? <Navigate to='/adminhome' /> : <Navigate to='/signin' />

          } />

        </Routes>
      </Router>
      <ToastContainer />
      {/* <AdminHome/> */}
    </>
  );
}

export default App;
