import { BASE_URL } from "../confiig"


export const getAllReferrals = async () => {
  const token = sessionStorage.getItem('token');
  if (token !== null) {
    return fetch(`${BASE_URL}/referrals`, {
      method: "GET",
      headers: {
        "token": `${token}`,
        "Content-Type": "application/json",
      },
    })
  } else {
    // Handle the case when the token is null
    return Promise.reject(new Error("Token is null"));
  }
}