import React, { useState, useEffect, useContext } from "react";
import Modal from "react-modal";

import "./Carousel.scss";
import Pagination from "../../components/Pagination/Pagination";
import { BASE_URL, IMG_URL } from "../../confiig";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../Loader/Loader";

// const URL=process.env.REACT_APP_BASE_URL
function Carousel(data) {
  const [carousels, setCarousels] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [addCarouselName, setAddCarouselName] = useState("");
  const [addCarouselDescription, setAddCarouselDescription] = useState("");

  const [addModalIsOpen, setAddModalIsOpen] = useState(false);

  const [imageFile, setImageFile] = useState(null);
  const [imageError, setImageError] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const token = sessionStorage.getItem("token");
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };
  const [isChanged, setIsChanged] = useState(false);

  const itemsPerPage = 5; // for example purposes only
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    setSearchTerm(""); // Reset search term when changing the page
  };
  const totalPages = Math.ceil(carousels.length / itemsPerPage);
  const indexOfLastProduct = currentPage * itemsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - itemsPerPage;
  const currentCarousels = carousels.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const handleAddCarousel = (name) => {
    // Open the edit popup
    setAddModalIsOpen(true);
  };

  const handleSaveAdd = async () => {
    try {
      const formData = new FormData();
      formData.append("title", addCarouselName);
      formData.append("description", addCarouselDescription);
      formData.append("image", imageFile);

      const response = await fetch(
        "https://api.pragatimitra.in/api/v1/carousel",
        {
          method: "POST",
          body: formData,
          headers: {
            token: `${token}`,
          },
        }
      );
      setAddModalIsOpen(false);
      toast.success("Carousel saved successfully!");
    } catch (error) {
      console.error("Error:", error);
      toast.error("Error occurred while saving carousel.");
    }
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setImageFile(file);
    setImageError("");
  };
  useEffect(() => {
    const fetchCarousels = async () => {
      try {
        const response = await fetch(
          "https://api.pragatimitra.in/api/v1/carousel",
          {
            headers: {
              token: `${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch carousels");
        }

        const data = await response.json();
        console.log("Carousel data:", data); // Add this console.log statement
        setCarousels(data.carousel);
        console.log("carousels", carousels);
        setIsLoading(false);
      } catch (error) {
        console.error("Error:", error);
        setIsLoading(false);
      }
    };

    fetchCarousels();
  }, []);

  const handleDeleteCategory = async (id) => {
    try {
      const response = await fetch(
        `https://api.pragatimitra.in/api/v1/carousel/${id}`,
        {
          method: "DELETE",
          headers: {
            token: `${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete carousel");
      }

      // Refresh the carousel data after deletion
      const updatedCarousels = carousels.filter((item) => item._id !== id);
      setCarousels(updatedCarousels);

      // Show success toast message
      toast.success("Carousel deleted successfully");
    } catch (error) {
      console.error("Error:", error);
      // Show error toast message
      toast.error("Failed to delete carousel");
    }
  };

  return (
    <div className="product_card">
      <div className="product_header">
        <h3>Carousel</h3>
      </div>

      <div className="d-flex justify-content-end align-item-center category-header">
        <div className="addItembtn">
          <button
            type="button"
            className="btn btn-success"
            onClick={handleAddCarousel}
          >
            Add Carousel
          </button>
        </div>
      </div>

      <div>
        <table>
          <thead>
            <tr>
              <th>Image</th>
              <th>Carousel title</th>

              <th>Carousel description</th>

              <th>Remove</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <Loader />
            ) : currentCarousels.length === 0 ? (
              <tr>
                <td colSpan="5">No Carousel found</td>
              </tr>
            ) : (
              currentCarousels.map((item) => (
                <tr key={item._id}>
                  <td>
                    <img src={`${IMG_URL}/${item.image}`} alt={item.image} />
                  </td>
                  <td>{item.title}</td>
                  <td>{item.description}</td>
                  <td>
                    <button
                      className="removeIcon"
                      onClick={() => handleDeleteCategory(item._id)}
                    >
                      <FontAwesomeIcon icon={faTrash} className="removeIcon" />
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <div>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </div>
      </div>

      <Modal
        isOpen={addModalIsOpen}
        onRequestClose={() => setAddModalIsOpen(false)}
        style={customStyles}
      >
        <div className="modal-header">
          <h2>Add Carousel</h2>
        </div>
        <div className="modal-body">
          <label htmlFor="carousel-title">Title</label>
          <input
            id="carousel-title"
            type="text"
            value={addCarouselName}
            onChange={(event) => setAddCarouselName(event.target.value)}
          />
          <label htmlFor="carousel-description">Description</label>
          <textarea
            id="carousel-description"
            value={addCarouselDescription}
            onChange={(event) => setAddCarouselDescription(event.target.value)}
          ></textarea>
          <label htmlFor="carousel-image">Image</label>
          <input
            id="carousel-image"
            type="file"
            onChange={handleFileChange}
            required
          />
          {imageError && <p className="error-image-message">{imageError}</p>}
        </div>
        <div className="modal-footer">
          <button
            className="cancel-button"
            onClick={() => setAddModalIsOpen(false)}
          >
            Cancel
          </button>
          <button className="save-button" onClick={handleSaveAdd}>
            Save
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default Carousel;
