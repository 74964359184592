import { BASE_URL } from "../confiig" 

export const signin = async(payload)=>{

    return fetch(`${BASE_URL}/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      })
}