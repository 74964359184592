import React, { useContext } from 'react'

import logo from "../../assets/newlogo.png"

import "./Header.scss"
import { EcomContext } from '../../context/Context'
import { useNavigate } from 'react-router-dom'

function Header() {
 const navigate= useNavigate()
  const {logoutHandler , isLoggin} = useContext(EcomContext)
  const onlogoutHandler =()=>{
    logoutHandler()
    navigate('/signin')
  }
  return (
    <div className=''>
      <nav className='container-fluid g-0'>
        <div className='navbar navbar-expand-lg navbar-light bg-white'>
          <a href="/" className='ms-3'>
            <img src={logo} alt="logo" />
          </a>
          {isLoggin && <button className="btn btn-primary ms-auto me-3" onClick={onlogoutHandler}>Logout</button>}
        </div>
      </nav>
    </div>
  )
}

export default Header